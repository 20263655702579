import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Button from '../components/Button'
import PostDisplay from '../components/PostDisplay'
import IndexPortfolioItem from '../components/IndexPortfolioItem'

import Lien from '../components/LienFleche'
import SIZES from '../utils/sizes'
import COLORS from '../utils/colors'

const StyledHero = styled.div`

    padding: 5vh 0 5vh;
    display: flex;
    justify-content:flex-end;
    z-index:3;
    position: relative;
    
  .hero-content{

      width:55%;

      .big{
          font-size:7.6rem;
          font-family: "Gilroy ExtraBold", "Catamaran", Arial, Helvetica, sans-serif;
          
      }

      .g-w{
        font-family: "Gilroy Extrabold", "Catamaran", Arial, Helvetica, sans-serif;
        
      }

      h1{
      
          text-align: right;
          line-height:5.6rem;
          
      }
      
      h3{
          margin-top: 48px;
          margin-bottom: 16px;
          margin-right: 0;
          text-align: right;
     
      }
  
      p.hero-buttons{
          text-align: right;
          margin:16px 0;          
          
      }
      
  }

  


  /******************MOUSE********************/
  .mouse {
      max-width: 2.5rem;
      width: 100%;
      height: auto;
  }
  
  .scroll {
      animation-name: scroll;
      animation-duration: 1.5s;
      animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
      animation-iteration-count: infinite;
      transform-origin: 50% 20.5px;
      will-change: transform, opacity;
      opacity: 1;
  }
  
  .scroll-link {
      position: absolute;
      bottom: -4rem;
      left: 50%;
      transform: translateX(-50%);
  }
  
  @keyframes scroll {
  
      0%, 20% {
          transform: translateY(0) scaleY(1);
      }
  
      100% {
          transform: translateY(36px) scaleY(2);
          opacity: 0;
      }
  
  }

  @media (max-width: ${SIZES.firstBreakPoint}){

    padding: 2vh 0 0;

    .hero-content{
      width:65%;

      h1{
        line-height:4.4rem;
        text-align: right;
      }

      h3{
          margin-top: 48px;
          margin-bottom: 48px;
          margin-right: 0;
      }
  
      p.hero-buttons{
          text-align: right;
          margin:48px 0;
      
      }

      .big{
          font-size: 5.8rem;
      }

      .g-w{
          font-size: 3rem;
          display: block;
    
      }
  }

  

    .mouse {
        display: none;
    }
        
  }

  @media (max-width: ${SIZES.thirdBreakPoint}){

    .hero-content{
      width:100%;
      margin:auto;

      h3, h1, p.hero-buttons{
        text-align:center;
      }

      h3{
        margin-bottom:16px;

      }

      h1{
        line-height:3.6rem;
      }

      .big{
        font-size: 4rem;
      }

      .g-w
      {        
          font-size: 1.95rem;      
      }

      p.hero-buttons{
        margin:24px 0;   
      }

    }    
  }

  @media (max-width: ${SIZES.fourthBreakPoint}){

    .hero-content{
      h1{
        font-size: 1.2rem;
        line-height:2.2rem;
      }

      .thin{
        letter-spacing: .2rem;
        }

      .big{
          font-size: 2.8rem;
      }

      .g-w{
          
          font-size: 1.3rem;
        
      }

      h3{
      
        margin: 24px 0 0 0;
      }

      p.hero-buttons{
        margin:0 0;   
      }

     
    
  }
  

`

const StyledSolutionsUl = styled.ul`

  margin:120px 0;
  display:flex;
  justify-content:space-between;
  list-style-type:none;
  flex-wrap:wrap;

  >li{
    width:calc(100% / 3);
    padding:48px;
   /* border:solid 1px #EEE;
    border-radius:4px;*/
    display:flex;
    flex-direction:column;
    justify-content:space-between;

    *{
      text-align:center;
    }

    .image-container{
      height:160px;
    }

    h3
    {
      margin-top:48px;
      margin-bottom:8px;
      font-size:1.4rem;
      text-align:center;
      letter-spacing:0px;
    }

    h4
    {
      margin-bottom:24px;
    }

    p
    {
      font-size:1rem;
      line-height:1.8rem;
      text-align:justify !important;

      >ul
      {
        margin:0 0 24px 0;
        
        display:inline;        
        list-style-type:circle;

        >li{
          text-align:left;
          margin-left:48px;
        }
      }

      >p
      {
        margin-top:24px;
      }
    }

    p.prix
    {
      align-self:center;
      margin-top:36px;
      
      span
      {
        font-size:1.6rem !important;
      }
    }
  }
 
  >li:nth-child(2){
    background: radial-gradient(circle, rgba(255,244,227,1) 22%, rgba(249,233,208,1) 77%, rgba(248,222,175,1) 100%);
  }

  }
  >li:last-child
  {
    width:100%;
    flex-direction:row;
    background: radial-gradient(circle, rgba(27,41,46,0.85) 0%, rgba(27,41,46,0.95) 44%, rgba(27,41,46,1) 100%);
    
    *
    {
      color:${COLORS.secundary};
    }

    >*
    {
      width:calc(100% / 2);

      h4{
        text-align:left;
      }
    }
  }

  @media (max-width: ${SIZES.secondBreakPoint}){

    margin:96px 0 120px 0;

    flex-direction:column;

    >li{
      margin-top:48px;
      width:100%;
    }

  }  

  @media (max-width: ${SIZES.thirdBreakPoint}){
    margin:0px 0 96px 0;

    >li
    {
      padding:48px 16px;

      p, li
      {
        font-size:1.2rem;
        line-height:2.6rem;
      }
    }
    
    

    >li:last-child
    {
      flex-direction:column;
     
    >*
    {
      width:100%;

      h4{
        text-align:center;
      }

      p.prix{
        text-align:center!important;
      }
    }

      
  
  }

`

const StyledInfographicUl = styled.ul`
  margin:72px 0 0 0;
  list-style-type:none;
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;

  li{

    width:224px;
    padding:0 8;

    .mediator{
      margin:auto;
      width:136px;
      height:136px;
      background:url(icones/mediator.svg) center center no-repeat;
      background-size:contain;
      position:relative;
      margin-bottom:24px;
     

        img{
          width:32px;
          position:absolute;
          right:10%;
          top:45%;
          transform: translateX(-50%) translateY(-50%);
        }

        span{
          position:absolute;
          top:24px;
          left:24px;

          font-family: "Gilroy ExtraBold", "Catamaran", Arial, Helvetica, sans-serif;
          font-size:1.6rem;
          color:white;
        }
    }

    .step{

      *{
        text-align:center;
      }

      h4{
        text-transform:uppercase;
        letter-spacing:4px;
        font-family: "Gilroy ExtraBold", "Catamaran", Arial, Helvetica, sans-serif;
        font-size:1rem;
      }

      p{
        margin-top:24px;
        font-size:1rem;
        line-height:1.8rem;
      }
    }
  }

  @media (max-width: ${SIZES.firstBreakPoint}){

    margin:0 0 120px 0;
        
    li{
      margin-top:48px;
    }
  }

  @media (max-width: ${SIZES.secondBreakPoint}){
    flex-direction:column;
    align-items:center;
  }

  @media (max-width: ${SIZES.thirdBreakPoint}){
     li
     {
      width:100%;

      .step
      {
        h4
        {
          font-size:1.4rem;
          line-height:2rem;
        }
      }
     } 
  }

`

/** Requête graphql pour tous les éléments (images, projets du portfolio, articles de blog) affichés dans la page) */
const getContentfulContent = graphql`
  query{
    
      allContentfulPortfolioItem (
        limit:6
        sort:{
          fields:number,
          order:DESC
        })
        {
            edges{
              node{
                  number
                  title
                  shortTitle
                  slug
                  category
                  featImage {
                      title
                      file {
                        url
                      }
                      gatsbyImageData
                  }
              
              }
            }
      }

      allContentfulBlogArticle(
        limit:3
        sort: {fields: pubDate, order: DESC}
        ) {
          edges {
            node {
              id
              title
              slug
              pubDate(formatString: "DD MMM YYYY", locale: "fr")
              excerpt {
                excerpt
              }
              
              featImage {
                id
                title
                file{
                  url
                }
                fluid{
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
      }
  }
    
`

/** Component page index */
const IndexPage = () => {
  const data = useStaticQuery(getContentfulContent)
  let numero = 0

  return (
        <Layout theme="index">
          <SEO title="Renforcez votre crédibilité avec un site internet vitrine valorisant et qui engage votre audience."/>
          <StyledHero id="top">
          
            
            <div className="hero-content">
            <h1><span className="big">Solutions</span><br /><span className="g-w"><span className="thin">graphiques</span> & <span className="thin">web</span></span></h1>
                <h3 className="sous-titre">Je vous accompagne dans chaque étape de la création de votre communication visuelle et digitale.</h3>
                <p className="hero-buttons"><Button ajoutClasse="reverse">Je demande un devis</Button></p>
            </div>

        

            <a className="scroll-link" href="#ego">
              <svg className="mouse" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 130" preserveAspectRatio="xMidYMid meet">
                <g fill="none" fill-rule="evenodd">
                    <rect width="70" height="118" x="1.5" y="1.5" stroke="#EEE" stroke-width="3" rx="36"/>
                    <circle className="scroll" cx="36.5" cy="31.5" r="4.5" fill="#EEE"/>
                </g>
              </svg>
            </a>
            
          </StyledHero>

         {}
          
              <section id="prestations" className="index-section">
                <div className="penche">
                <div className="stroke stroke-top"></div>
                  <article className="center">
                    <h2><span className="thin">Communiquez</span> facilement</h2>
                    <p>Vous créez votre entreprise ou souhaitez partir à la conquête de nouveaux clients mais vous ne savez pas par où commencer ? Je vous accompagne dans la mise en place de votre communication, qu'elle soit digitale ou imprimée.</p>    
                  </article>
                  <div className="stroke stroke-bottom"></div>
                </div>
                <div className="section-content">
                  <p>Toute action de communication efficace débute par la définition d'un plan d'action personnalisé, cohérent avec l'identité de votre entreprise et le profil de vos clients. Ensemble, nous déterminons quel est le meilleur canal de communication (imprimé/digital) entre votre entreprise et votre audience. Il ne reste plus qu'à créer les supports/outils (logo, flyer, site internet, réseaux sociaux, cartes de visite, plaquette de présentation, etc.), adaptés à votre message, votre profil, vos besoins et votre budget et à les diffuser !</p>
                  <StyledSolutionsUl>                  
                    <li>
                      <div>                 
                        <StaticImage className="image-container" src="../images/identite-visuelle.png" alt="Identité visuelle" objectFit="contain" layout="constrained" />
                        
                        <h3>Identité visuelle</h3>
                        <h4>Se démarquer avec une identité de marque personnalisée</h4>
                        <p>
                          <ul>
                            <li>charte graphique</li>
                            <li>logo</li>
      
                          </ul>
                          <p>Votre identité graphique est le reflet de votre personnalité entrepreneuriale. Elle souligne vos caractéristiques uniques et vous rend reconnaissable.</p>
                          </p>
                      </div> 
                      <p className="prix">à partir de <span>500€</span> HT</p>
                  </li>
                  <li>
                  <div> 
                    <StaticImage className="image-container" src="../images/site-vitrine.png" alt="Conception web" objectFit="contain" layout="constrained" />
                    
                    <h3>Site Vitrine Wordpress</h3>
                    <h4>Renforcer sa crédibilité avec un site clair et performant</h4>
                    <p>
                          <ul>
                            <li>arborescence du site</li>
                            <li>installation de WordPress</li>
                            <li>rédaction des contenus</li>
                            <li>création de pages responsive</li>
                            <li>optimisation & référencement</li>
                          </ul>
                          <p>Votre site internet est la vitrine indispensable de votre activité sur le web. Accueillez vos visiteurs dans un espace agréable et accessible, qui met votre expertise en valeur.</p>
                        </p>
                   
                         
                    </div> 
                    <p className="prix">à partir de <span>1279€</span> HT</p>
                  </li>
                  <li>
                  <div> 
                    <StaticImage className="image-container" src="../images/supports-com.png" alt="Supports imprimés" objectFit="contain" layout="constrained" />
                  
                    <h3>Supports de com'</h3>
                    <h4>Susciter l'intérêt avec des supports publicitaires attractifs et efficaces</h4>
                    <p>
                          <ul>
                            <li>affiche, flyer</li>
                            <li>plaquette commerciale</li>
                            <li>catalogue</li>
                            <li>carte de visite</li>
                          </ul>
                          <p>Communiquez efficacement sur un événement, un produit à valoriser, une richesse à promouvoir, grâce à des supports imprimés qui vous mettent en lumière.</p>
                        </p>
                   
                    </div> 
                    <p className="prix">à partir de <span>129€</span> HT</p>
                  </li>

                  <li>
                      <div>                 
                        <StaticImage className="image-container" src="../images/reseaux-sociaux.png" alt="réseaux sociaux" objectFit="contain" layout="constrained" />
                        
                        <h3>Réseaux sociaux</h3>
                      </div>
                      <div>
                        <h4>Partager et communiquer avec originalité et régularité</h4>
                        <p>
                        <ul>
                            <li>création et paramétrage de votre compte</li>
                            <li>programmation de vos publications</li>
                            <li>rédaction de contenus</li>
                            <li>création de templates visuels</li>
                        </ul>
                        <p>Captez l'intérêt de votre audience sur le long terme grâce à une présence régulière et originale sur les réseaux sociaux. Misez sur le bon réseau social pour créer une communauté engagée et la transformer en clientèle potentielle.</p>
                        </p>
                        <p className="prix">à partir de <span>59€</span> HT / mois</p>
                      </div> 
                      
                  </li>
                </StyledSolutionsUl>

                <h3>Déroulement d'un projet</h3> 

                <StyledInfographicUl>
                  <li>
                    <div className="mediator">
                      <img src="icones/ico1.svg" alt="echange"/>
                      <span>1</span>
                    </div>
                    <div className="step">
                      <h4>échange</h4>
                      <p>Nous définissons ensemble vos besoins / demandes / cibles. </p>
                    </div>
                  </li>
                  <li>
                    <div className="mediator">
                      <img src="icones/ico2.svg" alt="solution"/>
                      <span>2</span>
                    </div>
                    <div className="step">
                      <h4>solution</h4>
                      <p>Nous trouvons la formule parfaite, adaptée à votre budget et votre profil.</p>
                    </div>
                  </li>
                  <li>
                    <div className="mediator">
                      <img src="icones/ico3.svg" alt="validation"/>
                      <span>3</span>
                    </div>
                    <div className="step">
                      <h4>validation</h4>
                      <p>Vous validez les différentes étapes tout au long du projet.</p>
                    </div>
                  </li>
                  <li>
                    <div className="mediator">
                      <img src="icones/ico4.svg" alt="réalisation"/>
                      <span>4</span>
                    </div>
                    <div className="step">
                      <h4>réalisation</h4>
                      <p>Vous restez informé de la progression pendant la réalisation du projet.</p>
                    </div>
                  </li>
                  <li>
                    <div className="mediator">
                      <img src="icones/ico5.svg" alt="livraison"/>
                      <span>5</span>
                    </div>
                    <div className="step">
                      <h4>livraison</h4>
                      <p>Vous recevez votre outil, nous restons en contact pour le suivi du projet : formation, modification...</p>
                    </div>
                  </li> 
                </StyledInfographicUl>
             
             </div>
            </section>
            <p className="contact-me"><span>Quelle solution pour vous ?</span><br/><Button ajoutClasse="contact-me">Parlons-en !</Button></p>

        

           
              
             

          <section id="more" className="index-section">
              <div className="penche">
              <div className="stroke stroke-top"></div>
                <article className="center">
                  <h2><span className="thin">En</span> savoir plus<span className="thin">...</span></h2>
                  <p>Parcourez les dernières réalisations et le blog b-com.xyz</p>
                </article>
                <div className="stroke stroke-bottom"></div>
              </div>
              <div className="section-content">
                  
              <section id="portfolio" className="portfolioSection">
                <h3>Portfolio</h3>
         
                <div>
                  
                {data.allContentfulPortfolioItem.edges.map((edge) => {
                      let src = "../images/ciel-test.jpg"
                      let alt = "image"
                      
                      if(edge.node.featImage!=null){
                          src = edge.node.featImage.gatsbyImageData
                          alt = edge.node.featImage.title
                      
                      }
                      numero ++
                      return (
                        
                        <IndexPortfolioItem
                          number={numero}
                          title={edge.node.shortTitle}
                          category={edge.node.category}
                          slug={edge.node.slug}
                          src={src}
                          alt={alt}
                          
                        />
                        
                      )
                  })}

                </div>
                <Lien fleche="after" to="/portfolio">Plus de réalisations</Lien>
         
            </section>

            <p className="contact-me">Ces entreprises m'ont fait confiance pour donner vie à leurs projets web et design. Contactez-moi dès maintenant pour lancer le top départ de votre communication visuelle et digitale.<br/><Button ajoutClasse="contact-me">Je me lance</Button></p>

            <section id="blog">
                <h3>Blog</h3>
              
                <div>
                {data.allContentfulBlogArticle.edges.map((edge) => {
                  if(edge === data.allContentfulBlogArticle.edges[0]){
                    return (

                      <PostDisplay 
                        key={edge.node.slug}
                        title={edge.node.title}
                        pubDate={edge.node.pubDate}
                        slug={edge.node.slug}
                        excerpt={edge.node.excerpt.excerpt ? edge.node.excerpt.excerpt : "Extrait indisponible."}
                        src={edge.node.featImage.fluid}
                        alt={edge.node.featImage.title}
                        alaune="alaune"
                      />
                    )
                  }
                  return (

                    <PostDisplay
                      title={edge.node.title}
                      slug={edge.node.slug}
                      src={edge.node.featImage.fluid}
                      alt={edge.node.featImage.title}
                      pubDate={edge.node.pubDate}
                      excerpt={edge.node.excerpt.excerpt ? edge.node.excerpt.excerpt : "Extrait indisponible."}
                    />
                  )

                })}
                </div>
                <Lien fleche="after" to="/blog">Plus d'articles</Lien>
            </section>
</div>
          </section>

            
            
        </Layout>
           
     
    );
};

export default IndexPage;
