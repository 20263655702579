import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import styled from 'styled-components'
import Lien from './LienFleche'
import SIZES from '../utils/sizes'
import COLORS from  '../utils/colors'
import Button from './Button';



const StyledPostDisplay = styled.article`

    width:calc(100% / 3 - 16px);
    position: relative;
    background-color:${COLORS.fourthary};
    border-radius:4px;

    
    

    .post-card{
        position:relative;
        height:204px;   

        .post-thumbnail {
            height:100%;
            border-radius: 4px 4px 0 0;
            position:relative;
        }
    
        .filtre{
            position:absolute;
            top:0;
            background: linear-gradient(to top, rgba(255,255,255,0.3), rgba(255,255,255,0));
            width:100%;
            height:100%;
           
        }
    }

    

    .post-info{
        height:268px;
        background-color:${COLORS.fourthary};
        padding:24px 24px 16px;
        border-radius: 0 0 4px 4px;
        display:flex;
        flex-direction:column;
        justify-content:space-between;

        *{
            color:${COLORS.primary};
        }

        h4{
           
        
            text-transform:uppercase;
            text-align:center;
            font-family: "Gilroy ExtraBold", "Catamaran", Arial, Helvetica, sans-serif;
            margin-bottom :24px;
           
        }

        a{
            text-decoration:none;
        }

        .post-excerpt{
            font-size:1rem;
            line-height:1.6rem;
        }

    
    }

    &.alaune{
        position:relative;       
        border-radius:0 4px 4px 4px;
        .post-card
        {     
            .post-thumbnail
            {           
                border-radius: 0 4px 0 0;               
            }
        }
        .post-info{      
            background: radial-gradient(circle, rgba(255,244,227,1) 22%, rgba(249,233,208,1) 77%, rgba(248,222,175,1) 100%);
        }

        &:before{
            content:"à la une";
            position: absolute;
            top:0;
            left:0;
            transform:translateY(-100%);
            display:block;
            
            background-color:${COLORS.thirdary};
            color:${COLORS.secundary};
            padding:4px 16px;
            font-size:1rem;
            font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;
            border-radius: 4px 4px 0 0;
        }
    }

    &.alaune-de-blog{
        width:100%;
        display:flex;
        flex-direction:row-reverse;

        .post-card{
            height:100%;  
            width:calc(100% /3);

            .post-thumbnail {
                height:100%;
                border-radius: 0 4px 4px 0;
                
            }
        }
        .post-info{           
          
            width:calc(100% /3 *2);

            border-radius: 0 0 0 4px;
            padding:48px 48px 24px;
            height:100%;

            h4{           
                text-align:left;
                font-size:2.8rem;
            }
        }
    }


@media (max-width: ${SIZES.firstBreakPoint}){
    width:calc(100% /2 - 12px);
}   


@media (max-width: ${SIZES.secondBreakPoint}){

    width:100%;
    margin-right:0;
    .post-info{
        height:initial;
    }

    &.alaune-de-blog{
        .post-info{
            padding:24px 24px 8px;

            h4{
                font-size:2rem;
               
            }

        }
    }
    
}



@media (max-width: ${SIZES.fourthBreakPoint}){

    .post-card{      
        height:120px;
    }

    &.alaune-de-blog{

        flex-direction:column;
        .post-card, .post-info{
            width:100%;
        }

        .post-info{
            
            h4{
                font-size:1.2rem;
                text-align:center;
            }
        }
    }
}


`

const ArticleMeta = styled.div`

display:flex;
justify-content:space-between;
align-items:center;
    span{
        display:block;
        font-size:0.8rem;
        text-align:right;
        margin-left:24px;
    }


`


const PostDisplay = ({ title, slug, src, alt, pubDate, excerpt, alaune="" }) => {
    return (
        <StyledPostDisplay className={alaune}>
            <div className="post-card">
                
                <Image className="post-thumbnail"
                    fluid={src}
                    alt={alt}
                    imgStyle= { {objectFit:'cover'} } 
                />
                <div className="filtre"></div>
            </div>
            
            <div className="post-info">
                <Link to={`/blog/${slug}`}><h4>{title}</h4></Link>
                <p className="post-excerpt">{excerpt}</p>
                <ArticleMeta>
                    <Button ajoutClasse="access" to={`/blog/${slug}`}>Lire</Button>
         
                    <span className="date">{pubDate}</span>
                </ArticleMeta>
                
            </div>
            
        </StyledPostDisplay>
    );
};

PostDisplay.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    pubDate: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    alaune:PropTypes.string.isRequired
}

export default PostDisplay;