import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import SIZES from '../utils/sizes'

const StyledLienFleche = styled(Link)`
    display:inline-block;
    margin:48px auto 0;
    text-align: center;
    text-decoration: none;
    transition: transform .3s ease-out;
    font-family: "Gilroy Light", "Catamaran", Arial, Helvetica, sans-serif;


    &.reverse{
        color:$secundary;
        text-align:center !important;
        margin-top:0;
        
    }

    &.before::before{
        font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f30a";
    
        margin-right:16px;
        
    }

    &.before:hover{
        transform: translateX(-16px);
    }

    &.after::after{
        font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f30b";
        display:block;
        padding:4px;
        margin-left:16px;
      
    }

    &.after:hover{
        transform: translateX(16px);
    }

@media (max-width: ${SIZES.firstBreakPoint}){

    &.before:hover, &.after:hover{
            transform: none;      
        }
}

`

const LienFleche = ({to, fleche="", reverse="", children}) => {

    return (
        <StyledLienFleche className={fleche + ' ' + reverse} to={to}>
            {children}
        </StyledLienFleche>
    );
};

export default LienFleche;